/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import "fontsource-open-sans";
import { Helmet } from "react-helmet";
import { LanguageProvider } from "../../contexts/language-context";
import { PageUtagDataProvider } from "../../contexts/page-utag-data-context";
import TealiumScripts from "../tealium/tealium-scripts";

import isLinkUrlExternal from "../../helpers/isLinkUrlExternal";
import ExternalModal from "../external-modal/external-modal";
import favicon32 from "../../images/favicons/favicon-32.png";
import favicon128 from "../../images/favicons/favicon-128.png";
import favicon180 from "../../images/favicons/favicon-180.png";
import favicon192 from "../../images/favicons/favicon-192.png";

// NOTE: only import the icons we actually need to use, do not import the entire library.
// Also please keep the icon names in alphabetical order so that it is easier maintain

// FAR (regular) Pro
import {
  faAddressCard,
  faArrowRight as faArrowRightRegular,
  faArrowDown as faArrowDownRegular,
  faArrowUp as faArrowUpRegular,
  faBadgePercent,
  faCalendar as faCalendarRegular,
  faCalendarAlt as faCalendarAltRegular,
  faCar,
  faChartLine as faChartLineRegular,
  faCheck as faCheckRegular,
  faChevronLeft,
  faChevronRight,
  faClock as faClockRegular,
  faCreditCard as faCreditCardRegular,
  faDesktop as faDesktopRegular,
  faEnvelope as faEnvelopeRegular,
  faEnvelopeOpenText as faEnvelopeOpenTextRegular,
  faExchangeAlt,
  faExternalLink as faExternalLinkRegular,
  faExternalLinkAlt as faExternalLinkAltRegular,
  faFax as faFaxRegular,
  faFileAlt as faFileAltRegular,
  faFileCertificate,
  faFilePdf as faFilePdfRegular,
  faGasPump,
  faGlobe,
  faInfoCircle as faInfoCircleRegular,
  faKey,
  faMapMarkerAlt,
  faMedkit,
  faMobile,
  faMoneyCheckAlt,
  faPlane,
  faPhone as faPhoneRegular,
  faPhoneAlt as faPhoneAltRegular,
  faPhoneVolume,
  faQuestionCircle as faQuestionCircleRegular,
  faShuttleVan,
  faTachometer,
  faTags,
  faUniversity as faUniversityRegular,
  faVideo as faVideoRegular,
  faWallet as faWalletRegular,
  faUser as faUserRegular,
  faMountain as faMountainRegular,
  faHouse as faHouseRegular,
  faPencilRuler as faPencilRulerRegular,
  faPaintRoller as faPaintRollerRegular,
  faPercentage as faPercentageRegular,
  faShieldCheck as faShieldCheckRegular,
  faUmbrella,
  faUserShield
} from "@fortawesome/pro-regular-svg-icons";

// FAS (solid) Free
import {
  faArrowRight as faArrowRightSolid,
  faArrowDown as faArrowDownSolid,
  faArrowUp as faArrowUpSolid,
  faChevronDown,
  faChevronLeft as faChevronLeftSolid,
  faChevronRight as faChevronRightSolid,
  faChevronUp,
  faCheck,
  faCheckCircle,
  faDownload,
  faEnvelope as faEnvelopeSolid,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
  faFilePdf as faFilePdfSolid,
  faMapMarkerAlt as faMapMarkerAltSolid,
  faMinus as faMinusSolid,
  faPiggyBank as faPiggyBankSolid,
  faPlus,
  faPlayCircle,
  faSortUp,
  faTimesCircle,
  faVideo,
  faCalculator as faCalculatorSolid,
  faUser as faUserSolid
} from "@fortawesome/free-solid-svg-icons";

// FAS (solid) Pro
import {
  faAnalytics as faAnalyticsSolid,
  faBoxCheck as faBoxCheckSolid,
  faCity as faCitySolid,
  faCreditCard as faCreditCardSolid,
  faCommentSmile,
  faHandshakeAlt,
  faHomeHeart as faHomeHeartSolid,
  faMoneyBillWave as faMoneyBillWaveSolid,
  faMoneyCheckEdit,
  faHandsUsd as faHandsUsdSolid,
  faTruck as faTruckSolid
} from "@fortawesome/pro-solid-svg-icons";

// FAL (light) Pro
import {
  faAnalytics,
  faArrowsAlt,
  faArrowLeft,
  faArrowRight,
  faArrowDown,
  faArrowUp,
  faArrowToBottom,
  faArrowToTop,
  faAt,
  faBars,
  faBoxCheck,
  faBriefcase,
  faBuilding,
  faCalculator,
  faCalendar,
  faCalendarAlt,
  faChartLine,
  faCheck as faCheckLight,
  faChevronLeft as faChevronLeftLight,
  faChevronCircleLeft,
  faChevronCircleRight,
  faChevronDoubleDown,
  faChevronDoubleUp,
  faChevronRight as faChevronRightLight,
  faCity,
  faClipboardCheck,
  faClock,
  faCookieBite,
  faCommentDollar,
  faCreditCard,
  faDesktop,
  faDollarSign,
  faDoorOpen,
  faEnvelope,
  faEnvelopeOpenDollar,
  faEnvelopeOpenText,
  faExternalLink,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFax,
  faFilePdf,
  faFrown,
  faGlobe as faGlobeLight,
  faGraduationCap,
  faHandHoldingUsd,
  faHeartbeat,
  faFileAlt,
  faHandshake,
  faHandsUsd,
  faHammer,
  faHandHoldingHeart,
  faHome,
  faHouseUser,
  faLandmark,
  faLock,
  faLockAlt,
  faMapMarker,
  faMapMarkerAlt as faMapMarkerAltLight,
  faMapPin,
  faMinus,
  faMinusCircle,
  faMobile as faMobileLight,
  faMobileAlt,
  faMoneyBillWave,
  faMoneyCheck,
  faPaintRoller,
  faPercent,
  faPhone,
  faPhoneAlt,
  faPiggyBank,
  faPlay,
  faPlus as faPlusLight,
  faPlusCircle,
  faPrint,
  faQuestion,
  faQuestionCircle,
  faRabbitFast,
  faSearch,
  faServer,
  faShieldCheck,
  faShoppingCart,
  faShovel,
  faSortAlt,
  faStars,
  faSun,
  faTimes,
  faTimesCircle as faTimesCircleLight,
  faTools,
  faTruck,
  faTv,
  faUmbrellaBeach,
  faUniversalAccess,
  faUniversity,
  faUser,
  faUserFriends,
  faUsers,
  faWheelchair
} from "@fortawesome/pro-light-svg-icons";

// FAB (Brands)
import { faFacebookF, faLinkedinIn, faTwitter, faYoutube, faInstagram } from "@fortawesome/free-brands-svg-icons";
import "@fortawesome/fontawesome-svg-core/styles.css";
import "./global-layout.scss";
import DetectBrowser from "../../helpers/detectBrowser";
import ScrollToTopWrapper from "./scroll-to-top-wrapper";

// NOTE: please copy and paste the imported icons above and paste here to keep the library consistent.
library.add(
  // FAR (regular) Pro
  faAddressCard,
  faArrowRightRegular,
  faArrowDownRegular,
  faArrowUpRegular,
  faBadgePercent,
  faCalendarRegular,
  faCalendarAltRegular,
  faCar,
  faChartLineRegular,
  faCheck,
  faCheckRegular,
  faChevronLeft,
  faChevronRight,
  faClockRegular,
  faCreditCardRegular,
  faDesktopRegular,
  faEnvelopeRegular,
  faEnvelopeOpenTextRegular,
  faExchangeAlt,
  faExternalLinkRegular,
  faExternalLinkAltRegular,
  faFaxRegular,
  faFileAltRegular,
  faFileCertificate,
  faFilePdfRegular,
  faGasPump,
  faGlobe,
  faInfoCircleRegular,
  faKey,
  faMedkit,
  faMapMarkerAlt,
  faMobile,
  faMoneyCheckAlt,
  faPlane,
  faPhoneRegular,
  faPhoneAltRegular,
  faPhoneVolume,
  faInfoCircleRegular,
  faQuestionCircleRegular,
  faTachometer,
  faTags,
  faUniversityRegular,
  faVideoRegular,
  faWalletRegular,
  faUserRegular,
  faMountainRegular,
  faHouseRegular,
  faPencilRulerRegular,
  faPaintRollerRegular,
  faPercentageRegular,
  faShieldCheckRegular,
  faShuttleVan,
  faUmbrella,
  faUserShield,

  // FAS (solid) Free
  faChevronDown,
  faArrowRightSolid,
  faArrowDownSolid,
  faArrowUpSolid,
  faChevronLeftSolid,
  faChevronRight,
  faChevronRightSolid,
  faArrowDown,
  faArrowUp,
  faChevronUp,
  faCheck,
  faCheckCircle,
  faDownload,
  faEnvelopeSolid,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
  faFilePdfSolid,
  faMapMarkerAltSolid,
  faMinusSolid,
  faPlus,
  faPlayCircle,
  faPiggyBankSolid,
  faTimesCircle,
  faVideo,
  faCalculatorSolid,
  faUserSolid,

  // FAS (solid) Pro
  faAnalyticsSolid,
  faBoxCheckSolid,
  faCitySolid,
  faCreditCardSolid,
  faCommentSmile,
  faHandshakeAlt,
  faHomeHeartSolid,
  faMoneyBillWaveSolid,
  faMoneyCheckEdit,
  faHandsUsdSolid,
  faTruckSolid,

  // FAL (light)
  faAnalytics,
  faArrowsAlt,
  faArrowLeft,
  faArrowRight,
  faArrowToBottom,
  faArrowToTop,
  faAt,
  faBars,
  faBoxCheck,
  faBriefcase,
  faBuilding,
  faCalculator,
  faCalendar,
  faCalendarAlt,
  faChartLine,
  faCheckLight,
  faChevronLeftLight,
  faChevronCircleLeft,
  faChevronCircleRight,
  faChevronDoubleDown,
  faChevronDoubleUp,
  faChevronRightLight,
  faCity,
  faClipboardCheck,
  faClock,
  faCookieBite,
  faCommentDollar,
  faCreditCard,
  faDesktop,
  faDollarSign,
  faDoorOpen,
  faEnvelope,
  faEnvelopeOpenDollar,
  faEnvelopeOpenText,
  faExternalLink,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFax,
  faFilePdf,
  faGlobeLight,
  faGraduationCap,
  faHandHoldingUsd,
  faHandshake,
  faHandsUsd,
  faHeartbeat,
  faFileAlt,
  faFrown,
  faHammer,
  faHandHoldingHeart,
  faHome,
  faHouseUser,
  faLandmark,
  faLock,
  faLockAlt,
  faMapMarker,
  faMapMarkerAltLight,
  faMapPin,
  faMinus,
  faMinusCircle,
  faMobileAlt,
  faMobileLight,
  faMoneyBillWave,
  faMoneyCheck,
  faPaintRoller,
  faPercent,
  faPhone,
  faPhoneAlt,
  faPiggyBank,
  faPlay,
  faPlusLight,
  faPlusCircle,
  faPrint,
  faQuestion,
  faQuestionCircle,
  faRabbitFast,
  faSearch,
  faServer,
  faShieldCheck,
  faShoppingCart,
  faShovel,
  faSortAlt,
  faStars,
  faSun,
  faTimes,
  faTimesCircleLight,
  faTools,
  faTruck,
  faTv,
  faUmbrellaBeach,
  faUniversalAccess,
  faUniversity,
  faUser,
  faUserFriends,
  faUsers,
  faWheelchair,

  // FAB (Brands) Free
  faFacebookF,
  faLinkedinIn,
  faTwitter,
  faYoutube,
  faInstagram,
  faSortUp
);

let GlobalLayout = (props) => {
  const [showBrowserModal, setShowBrowserModal] = useState(false);
  const [showExtLinkModal, setShowExtLinkModal] = useState(false);
  const [extUrl, setExtUrl] = useState("/");
  const handleCloseModal = () => setShowExtLinkModal(false);
  const handleShowModal = (e) => {
    e.preventDefault();
    setExtUrl(e.target.href);
    setShowExtLinkModal(true);
  };

  useEffect(() => {
    setShowBrowserModal(true); // init on page load (this has nothing to do with external links)
    // check all links on page for external to pop modal
    const anchors = document.getElementsByTagName("a");

    for (const anchor of anchors) {
      // TODO: why do we care if the typeof href is a string, isn't it always a string?
      if (typeof anchor?.href === "string" && isLinkUrlExternal(anchor.href)) {
        console.log(`Global Layout: adding external link modal handler for "${anchor.href}"`);
        anchor.addEventListener("click", handleShowModal);
      }
    }

    // check all iframes on page for hotjar access
    const iframes = document.getElementsByTagName("iframe");
    for (const iframe of iframes) {
      // console.log(`Global Layout: adding hotjar tracking to "${iframe.src}"`);
      iframe.setAttribute("data-hj-allow-iframe", "");
    }
  }, []);

  const extLinkModalData = {
    handleClose: handleCloseModal,
    show: showExtLinkModal,
    url: extUrl
  };

  return (
    <ScrollToTopWrapper>
      <Helmet>
        <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
        <link rel="icon" type="image/png" sizes="128x128" href={favicon128} />
        <link rel="icon" type="image/png" sizes="180x180" href={favicon180} />
        <link rel="icon" type="image/png" sizes="192x192" href={favicon192} />
      </Helmet>
      <a className="sr-only sr-only-focusable text-white bg-success text-center d-block" href="#main-content">
        Skip to main content
      </a>
      <PageUtagDataProvider>
        <TealiumScripts />
        <LanguageProvider>
          {showBrowserModal && <DetectBrowser />}
          {showExtLinkModal && <ExternalModal {...extLinkModalData} />}
          {props.children}
        </LanguageProvider>
      </PageUtagDataProvider>
    </ScrollToTopWrapper>
  );
};
export default GlobalLayout;
