import React from "react";

// Images
import EqualHousingLogo from "../../images/equal-housing-lender-logo.svg";

const FooterLegal = (props) => (
  <>
    {props.isSpanish ? (
      <div id="footer-legal-spanish" className="col-12">
        <p className="text-muted text-sm mb-3">
          WaFd Bank es un DBA (Doing Business As) del Washington Federal Bank, Asociación Nacional. Miembro de FDIC.{" "}
          <span className="d-inline-block">
            Prestamista de vivienda igualitaria{" "}
            <img src={EqualHousingLogo} className="mb-1" alt="Equal Housing Lender logo" />
          </span>
        </p>
        <p className="text-muted text-sm">
          &copy; {new Date().getFullYear()}, WaFd Bank, Todos los derechos reservados. Compañía NMLSR #410394
        </p>
      </div>
    ) : (
      <div id="footer-legal" className="col-12">
        <p className="text-muted text-sm mb-3">
          WaFd Bank is a DBA (Doing Business As) of Washington Federal Bank. Member FDIC.{" "}
          <span className="d-inline-block">
            Equal Housing Lender <img src={EqualHousingLogo} className="mb-1" alt="Equal Housing Lender logo" />
          </span>
        </p>
        <p className="text-muted text-sm">
          &copy; {new Date().getFullYear()}, WaFd Bank, All Rights Reserved. NMLSR Company #410394
        </p>
      </div>
    )}
  </>
);

export default FooterLegal;
