import React, { Fragment } from "react";
import { Link } from "gatsby";
import { useLanguageContext } from "../../contexts/language-context";

// Components
import FooterLegal from "./footer-legal";

// Data
import { aboutUsLinksEnglish } from "../../data/footer-data";
import { aboutUsLinksSpanish } from "../../data/footer-data-spanish";

// Styles
import styles from "./footer.module.scss";

const FooterBottom = () => {
  const isSpanish = useLanguageContext();
  //console.log("footer isSpanish: ", isSpanish);
  const aboutUsLinks = isSpanish ? aboutUsLinksSpanish : aboutUsLinksEnglish;

  const isFirstLink = (index) => index === 0;
  const isLastLink = (index) => index === aboutUsLinks.length - 1;

  return (
    <div id="footer-bottom" className="container py-3 my-3 d-none d-md-block text-center">
      <div className="row">
        {/* ABOUT US LINKS */}
        <div className="col-sm-12">
          <nav role="navigation" aria-label="About WaFd Bank">
            <ul className={`nav justify-content-center font-weight-bold ${styles.bottomLinksSection}`}>
              {aboutUsLinks.map((item, index) => (
                <Fragment key={item.id}>
                  {item.show && (
                    <li className="nav-item text-sm mb-3">
                      <Link
                        id={item.id}
                        className={`text-primary d-inline-block ${isFirstLink(index) ? "" : "ml-2"} ${
                          isLastLink(index) ? "" : "mr-2"
                        }`}
                        to={item.url}
                      >
                        {item.text}
                        {item.icon ? (
                          <img src={item.icon} className="ml-1 mb-1" alt="" style={{ width: "31px", height: "14px" }} />
                        ) : (
                          ""
                        )}
                      </Link>
                    </li>
                  )}
                </Fragment>
              ))}
            </ul>
          </nav>
        </div>

        <FooterLegal isSpanish={isSpanish} />
      </div>
    </div>
  );
};
export default FooterBottom;
